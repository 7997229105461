<template>
  <v-card>
    <v-card-text>Below are the messages that will be displayed on the schedule change form, based on the effective date for when the messages will appear. Only one set of messages will be displayed at a time, matching the list for the maximum date prior to the current date. The order of the dates will be sorted when you save it after any changes that are made.</v-card-text>
    <v-alert v-if="isDefault" type="info" class="mb-0" outlined>The messages below are default ones, with default dates. Please adjust the dates and details below to match the actual desired dates.</v-alert>
    <v-tabs v-if="showTabs" v-model="scheduleMessageTab">
      <v-tab v-for="({ effDate, messages }, index) in scheduleChangeMessages" :key="'group-' + index">{{ effDate === '' ? 'Not Set' : yearMonDayFormat(effDate) }}</v-tab>
      <v-spacer></v-spacer>
      <v-btn @click="addDate()">
        <v-icon left small color="success">fas fa-plus-circle</v-icon>
        Add Date
      </v-btn>
    </v-tabs>
    <v-tabs-items v-if="showTabs" v-model="scheduleMessageTab">
      <v-tab-item v-for="({ effDate, add, drop, messages, grade, reqAppr }, index) in scheduleChangeMessages" :key="'group-tab-' + index">
        <v-row>
          <v-col :cols="12" :md="3">
            <v-card>
              <v-card-text>
                <date-picker :value="yearMonDayFormat(effDate)" label="Effective Date" solo hide-details dense @input="(date) => { scheduleChangeMessages[index].effDate = new Date(date + ' 08:00') }"></date-picker>
                <v-switch v-model="scheduleChangeMessages[index].add" :label="(add ? 'Can' : 'Cannot') + ' Add Courses'" hide-details></v-switch>
                <v-switch v-model="scheduleChangeMessages[index].drop" :label="(drop ? 'Can' : 'Cannot') + ' Drop Courses'" hide-details></v-switch>
                <v-switch v-model="scheduleChangeMessages[index].grade" :label="grade ? 'W/F Grade Required' : 'No Grade Required'" hide-details>
                  <template v-slot:append>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="info">fas fa-question-circle</v-icon>
                      </template>
                      <span>Whether an instructor for a dropped course will be required to select a grade for the student, with the option of W or F.</span>
                    </v-tooltip>
                  </template>
                </v-switch>
                <v-switch v-model="scheduleChangeMessages[index].reqAppr" :label="reqAppr ? 'Approval Required' : 'Skip Approvals'" hide-details>
                  <template v-slot:append>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="info">fas fa-question-circle</v-icon>
                      </template>
                      <span>Whether the instructor and advisor approval is required. If no, then all changes are sent straight to the Records office to process.</span>
                    </v-tooltip>
                  </template>
                </v-switch>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="scheduleChangeMessages.splice(index, 1)">
                  <v-icon small left color="red">fas fa-times-circle</v-icon>
                  Remove Date
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col :cols="12" :md="9">
            <v-toolbar>
              <v-btn outlined color="success" @click="scheduleChangeMessages[index].messages.push({ text: '', icon: '', color: 'info' })">
                <v-icon small left color="success">fas fa-plus-circle</v-icon>
                Add Message
              </v-btn>
              <v-btn color="success" @click="save()">
                <v-icon left>fal fa-save</v-icon>
                Save
              </v-btn>
            </v-toolbar>
            <v-alert v-for="(message, msgIndex) in messages" :key="'group-tab-' + index + '-msg-' + msgIndex" :colored-border="true" border="left" :color="message.color" light>
              <template v-slot:prepend>
                <v-icon :color="message.color" style="margin-right: 16px;width:27px;text-align:center;">{{ message.icon || 'fas fa-exclamation' }}</v-icon>
              </template>
              <template v-slot:append>
                <v-menu dense left>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>fas fa-ellipsis-v</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-menu offset-x dense left>
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                          <v-list-item-title>Change Icon</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-list>
                        <v-list-item v-for="({ icon }, iconIndex) in icons" :key="'group-' + index + '-msg-' + msgIndex + '-icon-' + iconIndex" @click="message.icon = icon">
                          <v-list-item>
                            <v-icon>{{ icon }}</v-icon>
                          </v-list-item>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu offset-x dense left>
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                          <v-list-item-title>Change Color</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-list>
                        <v-list-item v-for="({ text, value }, colorIndex) in colors" :key="'group-' + index + '-msg-' + msgIndex + '-color-' + colorIndex" @click="message.color = value">
                          <v-list-item-title>{{ text }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-list-item @click="messages.splice(msgIndex, 1)">
                      <v-list-item-title>Remove Message</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <v-textarea v-model="message.text" rows="2" hide-details outlined label="Message Text" light></v-textarea>
            </v-alert>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <v-sheet v-else style="background-color: #EEEEEE">
      <v-timeline dense align-top>
        <v-timeline-item v-for="({ effDate, add, drop, messages, grade, reqAppr, expanded }, index) in scheduleChangeMessages" :key="'timeline-' + effDate" small :color="add && drop ? 'success' : (add || drop ? 'warning' : 'error')">
          <v-card color="grey darken-1" class="mr-4">
            <v-toolbar @click="updateField(index, 'expanded', !expanded)">
              <div style="max-width:120px !important">
                <date-picker :value="yearMonDayFormat(effDate)" label="Effective Date" solo hide-details dense @input="(date) => { updateField(index, 'effDate', new Date(date + ' 08:00')) }"></date-picker>
              </div>
              <!-- <v-menu :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon>fal fa-pencil</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <date-picker :value="yearMonDayFormat(effDate)" label="Effective Date" solo hide-details dense @input="(date) => { scheduleChangeMessages[index].effDate = new Date(date + ' 08:00') }"></date-picker>
                    <v-switch v-model="scheduleChangeMessages[index].add" :label="(add ? 'Can' : 'Cannot') + ' Add Courses'" hide-details></v-switch>
                    <v-switch v-model="scheduleChangeMessages[index].drop" :label="(drop ? 'Can' : 'Cannot') + ' Drop Courses'" hide-details></v-switch>
                    <v-switch v-model="scheduleChangeMessages[index].grade" :label="grade ? 'W/F Grade Required' : 'No Grade Required'" hide-details>
                      <template v-slot:append>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="info">fas fa-question-circle</v-icon>
                          </template>
                          <span>Whether an instructor for a dropped course will be required to select a grade for the student, with the option of W or F.</span>
                        </v-tooltip>
                      </template>
                    </v-switch>
                    <v-switch v-model="scheduleChangeMessages[index].reqAppr" :label="reqAppr ? 'Approval Required' : 'Skip Approvals'" hide-details>
                      <template v-slot:append>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="info">fas fa-question-circle</v-icon>
                          </template>
                          <span>Whether the instructor and advisor approval is required. If no, then all changes are sent straight to the Records office to process.</span>
                        </v-tooltip>
                      </template>
                    </v-switch>
                  </v-card-text>
                </v-card>
              </v-menu> -->
              <v-spacer></v-spacer>
              <v-chip :color="add ? 'success' : 'error'" class="mr-2" label @click.stop="updateField(index, 'add', !add)">
                <v-icon left small>fas fa-{{ add ? 'check' : 'times' }}-circle</v-icon>
                Add
              </v-chip>
              <v-chip :color="drop ? 'success' : 'error'" class="mr-2" label @click.stop="updateField(index, 'drop', !drop)">
                <v-icon left small>fas fa-{{ drop ? 'check' : 'times' }}-circle</v-icon>
                Drop
              </v-chip>
              <v-chip :color="grade ? 'success' : 'error'" class="mr-2" label @click.stop="updateField(index, 'grade', !grade)">
                <v-icon left small>fas fa-{{ grade ? 'check' : 'times' }}-circle</v-icon>
                Grade Required
              </v-chip>
              <v-chip :color="reqAppr ? 'success' : 'error'" class="mr-2" label @click.stop="updateField(index, 'reqAppr', !reqAppr)">
                <v-icon left small>fas fa-{{ reqAppr ? 'check' : 'times' }}-circle</v-icon>
                Approval Required
              </v-chip>
              <v-spacer></v-spacer>
              <v-chip label small outlined>{{ messages.length }} message{{ messages.length === 1 ? '' : 's' }}</v-chip>
              <v-btn icon @click.stop="updateField(index, 'expanded', !expanded)">
                <v-icon>fal fa-chevron-{{ expanded ? 'up' : 'down' }}</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text v-if="expanded">
              <v-alert v-for="(message, msgIndex) in messages" :key="'group-tab-' + index + '-msg-' + msgIndex" :colored-border="true" border="left" :color="message.color" light>
                <template v-slot:prepend>
                  <v-icon :color="message.color" style="margin-right: 16px;width:27px;text-align:center;">{{ message.icon || 'fas fa-exclamation' }}</v-icon>
                </template>
                <template v-slot:append>
                  <v-menu dense left>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon>
                        <v-icon>fas fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-menu offset-x dense left>
                        <template v-slot:activator="{ on }">
                          <v-list-item v-on="on">
                            <v-list-item-title>Change Icon</v-list-item-title>
                          </v-list-item>
                        </template>
                        <v-list>
                          <v-list-item v-for="({ icon }, iconIndex) in icons" :key="'group-' + index + '-msg-' + msgIndex + '-icon-' + iconIndex" @click="message.icon = icon">
                            <v-list-item>
                              <v-icon>{{ icon }}</v-icon>
                            </v-list-item>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-menu offset-x dense left>
                        <template v-slot:activator="{ on }">
                          <v-list-item v-on="on">
                            <v-list-item-title>Change Color</v-list-item-title>
                          </v-list-item>
                        </template>
                        <v-list>
                          <v-list-item v-for="({ text, value }, colorIndex) in colors" :key="'group-' + index + '-msg-' + msgIndex + '-color-' + colorIndex" @click="message.color = value">
                            <v-list-item-title>{{ text }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-list-item @click="messages.splice(msgIndex, 1)">
                        <v-list-item-title>Remove Message</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <v-textarea v-model="message.text" rows="2" hide-details outlined label="Message Text" light></v-textarea>
              </v-alert>
            </v-card-text>
            <v-card-actions v-if="expanded">
              <v-btn @click="scheduleChangeMessages[index].messages.push({ text: '', icon: '', color: 'info' })">
                <v-icon small left color="success">fas fa-plus-circle</v-icon>
                Add Message
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="removeDate(index)">
                <v-icon left>fas fa-times-circle</v-icon>
                Remove Date
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <v-btn color="success" @click="addDate()">
        <v-icon left>fas fa-plus-circle</v-icon>
        Add Date
      </v-btn>
      <v-btn color="success" fab fixed bottom right @click="save()">
        <v-icon>fal fa-save</v-icon>
      </v-btn>
    </v-sheet>
  </v-card>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { yearMonDayFormat } from '../../../helpers/formatters'
export default {
  components: {
    DatePicker: () => import('@/components/forms/inputs/DatePicker')
  },
  props: {
    termId: {
      type: String,
      required: true
    },
    termStart: {
      type: String,
      required: true
    },
    termEnd: {
      type: String,
      required: true
    }
  },
  setup (props, { root }) {
    const showTabs = ref(false)
    const scheduleChangeMessages = ref([])

    const required = ref([v => !!v || 'Required'])

    const schedule = ref({
      changeFee: '',
      disallowAdd: '',
      instructorWF: '',
      lastDrop: ''
    })
    const scheduleMessageTab = ref(0)
    const colors = ref([
      { text: 'Succcess (Green)', value: 'success' },
      { text: 'Warning (Yellow)', value: 'warning' },
      { text: 'Error (Red)', value: 'error' },
      { text: 'Info (blue)', value: 'info' }
    ])
    const icons = ref([
      { icon: 'fal fa-usd-circle', description: 'Dollar Sign' },
      { icon: 'fal fa-exclamation-triangle', description: 'Exclamation Triangle' },
      { icon: 'fas fa-exclamation', description: 'Exclamation' }
    ])
    function save () {
      let arr = Object.assign([], scheduleChangeMessages.value)
      arr.sort((a, b) => {
        if (a.effDate < b.effDate) return 0
        else return 1
      })
      scheduleChangeMessages.value = arr.map((row) => { return { ...row, expanded: false } })
      root.$feathers.service('system/term').patch(props.termId, { scheduleChangeMessages: arr }).then((data) => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Schedule change messages were saved successfully.' })
      })
    }

    const isDefault = ref(false)
    watch(() => props.termId, () => {
      if (props.termId != null && props.termId !== '') {
        root.$feathers.service('system/term').get(props.termId).then((item) => {
          if ('scheduleChangeMessages' in item && item.scheduleChangeMessages.length > 0) {
            scheduleChangeMessages.value = item.scheduleChangeMessages
          } else {
            isDefault.value = true
            // Set default messages
            const arr = []
            const temp = new Date(item.start)
            temp.setMonth(temp.getMonth() - 6)
            arr.push({
              effDate: temp,
              add: true,
              drop: true,
              grade: false,
              reqAppr: false,
              messages: [
                { text: 'You are free to make any changes until the semester begins, which will be processed by the Records Office and updated in Banner.', color: 'info', icon: 'fas fa-exclamation' }
              ]
            })
            let start = new Date(item.start)
            arr.push({
              effDate: start,
              add: true,
              drop: true,
              grade: false,
              reqAppr: true,
              messages: [
                { text: 'Any added or dropped course will require approval from your advisor and the Records Office.', color: 'info', icon: 'fas fa-exclamation' }
              ]
            })
            const feeDate = new Date(item.start)
            feeDate.setDate(feeDate.getDate() + 7)
            arr.push({
              effDate: feeDate,
              add: false,
              drop: true,
              grade: false,
              reqAppr: true,
              messages: [
                { text: 'Any added or dropped course requires approval from the course instructor, your advisor, and the Records Office.', color: 'info', icon: 'fas fa-exclamation' },
                { text: 'A $20 course change fee will be applied to your tuition account for each change.', color: 'error', icon: 'fal fa-usd-circle' }
              ]
            })
            const afterAddDate = new Date(item.start)
            afterAddDate.setDate(afterAddDate.getDate() + 14)
            arr.push({
              effDate: afterAddDate,
              add: false,
              drop: true,
              grade: false,
              reqAppr: true,
              messages: [
                { text: 'Dropping a course at this time results in a marker of "W" being recorded on your academic record for the course.', color: 'error', icon: 'fal fa-exclamation-triangle' },
                { text: 'Any dropped course requires approval from your advisor and the Records Office.', color: 'info', icon: 'fas fa-exclamation' },
                { text: 'Because we are more than 2 weeks into the term, you can only drop courses and cannot add courses.', color: 'info', icon: 'fas fa-exclamation' },
                { text: 'A $20 course change fee will be applied to your tuition account for each change.', color: 'error', icon: 'fal fa-usd-circle' }
              ]
            })
            const afterDropW = new Date(item.end)
            afterDropW.setDate(afterDropW.getDate() - 46)
            arr.push({
              effDate: afterDropW,
              add: false,
              drop: true,
              grade: true,
              reqAppr: true,
              messages: [
                { text: 'Dropping a course at this time will require the instructor to assign either a marker of "W" or a grade of "F" to your academic record.', color: 'error', icon: 'fal fa-exclamation-triangle' },
                { text: 'Because we are more than 2 weeks into the term, you can only drop courses and cannot add courses.', color: 'info', icon: 'fas fa-exclamation' },
                { text: 'A $20 course change fee will be applied to your tuition account for each change.', color: 'error', icon: 'fal fa-usd-circle' }
              ]
            })
            const afterLastDrop = new Date(item.end)
            afterLastDrop.setDate(afterLastDrop.getDate() - 21)
            arr.push({
              effDate: afterLastDrop,
              add: false,
              drop: false,
              grade: false,
              reqAppr: true,
              messages: [
                { text: 'It is not possible to drop or add a class at this time', color: 'error', icon: 'fal fa-exclamation-triangle' },
                { text: 'Registrations are locked for the semester.', color: 'error', icon: 'fal fa-exclamation-triangle' },
                { text: 'You will receive a grade for any class you are registered for.', color: 'error', icon: 'fal fa-exclamation-triangle' }
              ]
            })
            scheduleChangeMessages.value = arr
          }
        })
      }
    })

    function addDate () {
      scheduleChangeMessages.value.push({ effDate: '', add: false, drop: true, grade: false, reqAppr: true, messages: [], expanded: true })
    }
    function removeDate (index) {
      if (confirm('Are you sure you want to remove this date?')) {
        scheduleChangeMessages.value.splice(index, 1)
      }
    }

    function updateField (index, field, value) {
      const temp = { ...scheduleChangeMessages.value[index] }
      temp[field] = value
      scheduleChangeMessages.value.splice(index, 1, temp)
    }

    return {
      showTabs,
      scheduleChangeMessages,
      required,
      schedule,
      scheduleMessageTab,
      colors,
      icons,
      save,
      isDefault,
      addDate,
      removeDate,
      updateField,
      yearMonDayFormat
    }
  }
}
</script>
